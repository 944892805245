<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-header class="text-right">
      <a
        :href="service.domain"
        style="float: left; display: flex; align-items: left"
      >
        <img
          v-if="service.logo"
          :src="require(`@/assets/img/svg/${service.logo}`)"
          style="height: 40px; padding: 5px"
        />
      </a>
    </el-header>
    <el-main class="content">
      <el-row type="flex" justify="center" align="center">
        <el-col :xs="18" :sm="6" :md="6">
          <el-form ref="form" :model="userSecurity" :rules="rules">
            <h1 align="center">
Ingresa una nueva contraseña
</h1>
            <div class="divider"></div>
            <el-form-item prop="passwordNew">
              <el-input
                v-model="userSecurity.passwordNew"
                type="password"
                :placeholder="$t('password')"
                :maxlength="32"
                auto-complete="off"
              >
                <i slot="prefix" class="el-input__icon fa fa-user-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordNewCheck">
              <el-input
                v-model="userSecurity.passwordNewCheck"
                type="password"
                :placeholder="$t('confirmPassword')"
                :maxlength="32"
                auto-complete="off"
              >
                <i slot="prefix" class="el-input__icon fa fa-user-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="mini"
                type="primary"
                :disabled="!recoveryIsValid"
                :loading="loading"
                :class="'button-full-width button-' + service.code"
                @click.prevent="updatePassword"
              >
                {{ $t('changePassword') }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
    <el-footer
      style="
        border-top: 1px solid #e6e6e6;
        background-color: #fafafa;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col
          :sm="12"
          style="text-align: center; font-size: 0.875rem; line-height: 1.71"
        >
          <el-link
            href="http://soporte.nubixar.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Soporte
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://nubixar.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://nubixar.com/contacto"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contactanos
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="http://estado.nubixar.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Estado del servicio
          </el-link>
          &nbsp;-&nbsp;
          <el-link
            href="https://www.facebook.com/nubixar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook"></i>
          </el-link>
          &nbsp;&nbsp;
          <el-link
            href="https://twitter.com/nubixar_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter"></i>
          </el-link>
        </el-col>
      </el-row>
    </el-footer>
    <el-footer
      style="
        border: solid 1px #e6e6e6;
        background-color: #f1f1f1;
        font-size: 18px;
      "
    >
      <el-row type="flex" justify="center">
        <el-col
          :sm="12"
          style="text-align: right; font-size: 0.875rem; line-height: 1.71"
        >
          Copyright © 2023 - ALIADDO SAS. Todos los derechos reservados
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'Recovery',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la nueva contraseña'))
      } else {
        if (this.userSecurity.passwordNewCheck !== '') {
          this.$refs.form.validateField('passwordNewCheck')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la contraseña nuevamente'))
      } else if (value !== this.userSecurity.passwordNew) {
        callback(new Error('La contraseña no es igual!'))
      } else {
        callback()
      }
    }

    return {
      userSecurity: {
        userId: '',
        userRecoveryCode: '',
        serviceId: '-1',
        passwordNew: '',
        passwordNewCheck: ''
      },
      service: {
        id: '-1',
        code: '',
        domain: '',
        logo: ''
      },
      recoveryIsValid: false,
      loading: false,
      rules: {
        passwordNew: [{ validator: validatePass, trigger: 'blur' }],
        passwordNewCheck: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    get() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `users/${this.$route.params.id}/validateRecovery/?code=${this.$route.params.code}`
        )
        .then(
          response => {
            _this.userSecurity.userId = this.$route.params.id
            _this.userSecurity.recoveryCode = this.$route.params.code
            _this.userSecurity.serviceId = _this.$route.params.service
            _this.recoveryIsValid = true
            _this.loading = false
          },
          response => {
            _this.loading = false
          }
        )
    },
    updatePassword() {
      const _this = this
      _this.$refs.form.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http
            .patch(
              `users/${this.$route.params.id}/updatePasswordRecovery`,
              _this.userSecurity
            )
            .then(
              response => {
                // _this.$swal(_this.$t('ok'), _this.$t('processSuccess'), 'success')
                this.$notify({
                  title: 'Success',
                  message:
                    'Password actualizado con exito, en 3 segundos vas a ser redireccionado al panel de autenticacion!',
                  type: 'success'
                })
                _this.$refs.form.resetFields()

                setTimeout(() => {
                  window.location.replace(_this.service.domain)
                }, 3000)
              },
              response => {
                _this.loading = false
              }
            )
        }
      })
    },
    init() {
      const _this = this
      _this.loading = true
      _this.service.id = _this.$route.params.service

      if (_this.service.id === '0') {
        _this.service.code = 'hub'
        _this.service.domain = '//hub.aliddo.com'
      } else if (_this.service.id === '1') {
        _this.service.code = 'fe'
        _this.service.domain = '//fe.aliaddo.com'
      } else if (_this.service.id === '2') {
        _this.service.code = 'aliaddo'
        _this.service.domain = '//app.aliaddo.com'
      } else if (_this.service.id === '8') {
        _this.service.code = 'aliaddopos'
        _this.service.domain = '//app.aliaddo.com'
      } else if (_this.service.id === '10') {
        _this.service.code = 'aliaddonominafull'
        _this.service.domain = '//nomina.aliaddo.com'
      } else if (_this.service.id === '3') {
        _this.service.code = 'hrm'
        _this.service.domain = '//nomina.aliaddo.com'
      } else if (_this.service.id === '7') {
        _this.service.code = 'isv'
        _this.service.domain = '//isv.aliaddo.com'
      } else if (_this.service.id === '12') {
        _this.service.code = 'aliaddonominaisv'
        _this.service.domain = '//isv.aliaddo.com'
      } else {
        _this.service.id = '-1'
      }

      _this.service.logo = 'logo-aliaddo.svg'

      document.body.classList.add(`el-body-${_this.service.code}`)

      if (parseInt(_this.service.id) < 0) {
        _this.loading = false
        _this.$swal('Opps', 'No existe el servicio!', 'error')

        return false
      }

      _this.get()
    }
  }
}
</script>
<style lang="css">
.el-input__prefix {
  padding-left: 5px;
}
body {
  color: black !important;
  display: flex !important;
  background-size: cover !important;
  flex-direction: column !important;
  padding: 0px !important;
}
body.el-body-hub > * > main {
  background-color: #c9abd1 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-fe > * > main {
  background-color: #c1e4f8 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-aliaddo > * > main {
  background-color: #c1e4f8 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-hrm > * > main {
  background-color: #b4dc9f !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
body.el-body-isv > * > main {
  background-color: #c9abd1 !important;
  background-image: url('../../assets/img/background-cover.jpg') !important;
}
.el-header {
  background-color: #fff !important;
  color: #6c757d !important;
  line-height: 60px;
  height: 50px !important;
}
.el-form {
  padding-top: 50px;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 10px;
}
.image {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 4px;
}
.button-full-width {
  width: 100%;
}
.button-hub {
  color: #fff;
  background-color: #554188 !important;
  border-color: #554188 !important;
  height: 3em;
  font-size: 14px;
}
.button-fe {
  color: #fff;
  background-color: #1a709c !important;
  border-color: #1a709c !important;
  height: 3em;
  font-size: 14px;
}
.button-aliaddo {
  color: #fff;
  background-color: #1a709c !important;
  border-color: #1a709c !important;
  height: 3em;
  font-size: 14px;
}
.button-hrm {
  color: #fff;
  background-color: #426b21 !important;
  border-color: #426b21 !important;
  height: 3em;
  font-size: 14px;
}
.button-isv {
  color: #fff;
  background-color: #554188 !important;
  border-color: #554188 !important;
  height: 3em;
  font-size: 14px;
}
.button-hub:hover {
  background-color: #9166dc !important;
  border-color: #9166dc !important;
}
.button-fe:hover {
  background-color: #4891b7 !important;
  border-color: #4891b7 !important;
}
.button-aliaddo:hover {
  background-color: #4891b7 !important;
  border-color: #4891b7 !important;
}
.button-hrm:hover {
  background-color: #6f9452 !important;
  border-color: #6f9452 !important;
}
.button-isv:hover {
  background-color: #9166dc !important;
  border-color: #9166dc !important;
}
div .el-form-item__error {
  color: #000;
}
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #e9f8d2;
}

.el-input .el-input__inner {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 3em;
  border-color: rgb(119, 119, 119);
}
.terms {
  color: rgb(78, 78, 78);
}
.el-footer {
  flex-shrink: 0;
  padding: 16px 20px;
}
.content {
  flex: 1 0 auto;
}
</style>
